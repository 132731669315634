import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import Bg from "../Assets/Lovepik_com-400159452-urban-construction-lines.jpg";
import anhnen1 from '../Assets/anhbanner.jpg'
import anhnen4 from '../Assets/NhaMayDienGio.png'
import anhnen2 from '../Assets/TruongTH DuyTan.png'
import anhnen3 from '../Assets/K7.png'

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    "https://media.istockphoto.com/id/579157120/vector/construction-skyline-scene-blue-background.jpg?s=612x612&w=0&k=20&c=igltrPF2-r-2onl82frfP0wk8Q_TL428NE1UErg9UBM=",
    "https://png.pngtree.com/thumb_back/fh260/back_our/20190625/ourmid/pngtree-simple-atmospheric-gradient-urban-construction-enterprise-ppt-background-image_260238.jpg",
    "https://example.com/image3.jpg",
    "https://example.com/image4.jpg",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentSlide, slides.length]);
  return (
    <div className="relative h-[400px]">
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
        {/* <img
          src={Bg}
          alt="Background Image"
          className="h-full w-full object-cover"
        /> */}
        <Carousel className="w-full h-[400px]" autoplay>
          <div className="">
            <h3>
              <img
                // src='https://img.freepik.com/free-photo/detail-shot-skyscrapers_1359-995.jpg?size=626&ext=jpg&ga=GA1.1.1141335507.1718236800&semt=ais_user'
                src={anhnen1}
                alt="Background Image"
                className="w-full h-[400px] object-cover"
              />
            </h3>
          </div>
          <div className="">
            <h3>
              <img
                // src="https://st.depositphotos.com/1010683/2515/i/450/depositphotos_25158297-stock-photo-exterior-of-building.jpg"
                src={anhnen2}
                alt="Background Image"
                className="w-full h-[400px] object-cover"
              />
            </h3>
          </div>
          <div className="">
            <h3>
              <img
                // src="https://4kwallpapers.com/images/wallpapers/modern-architecture-building-office-geometric-blue-2560x1440-6640.jpg"
                src={anhnen3}
                alt="Background Image"
                className="w-full h-[400px] object-cover"
              />
            </h3>
          </div>
          <div className="">
            <h3>
              <img
                // src="https://4kwallpapers.com/images/wallpapers/modern-architecture-building-office-geometric-blue-2560x1440-6640.jpg"
                src={anhnen4}
                alt="Background Image"
                className="w-full h-[400px] object-cover"
              />
            </h3>
          </div>
        </Carousel>
      </div>
      {/* <div className="relative z-10 flex h-full items-center justify-center px-5">
        <div className="text-slate-100">
          <h1 className="text-4xl font-bold text-center mt-10 md:mt-20 text-shadow-black text-shadow-y-2">
            Công ty Cổ phần Đầu tư và Xây dựng Minh Long Việt Nam
          </h1>
          <p className="mt-4 text-xl text-center text-shadow-black text-shadow-y-2 mb-10 md:mb-20">
            Sẽ cùng quý vị tạo nên những công trình chất lượng, bền vưng theo
            thời gian.
          </p>
        </div>
      </div> */}
    </div>

    // <div className="fixed top-0 left-0 w-full h-full z-[-1]">
    //   {slides.map((slide, index) => (
    //     <div
    //       key={index}
    //       className={`absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-1000 ${
    //         index === currentSlide ? 'opacity-100' : ''
    //       }`}
    //       style={{
    //         backgroundImage: `url(${slide})`,
    //         backgroundSize: 'cover',
    //         backgroundPosition: 'center',
    //       }}
    //     />
    //   ))}

    // </div>
  );
};

export default Banner;
